.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--container-bg-color);
    height: 56px;
    border-bottom: 2px solid var(--border-color);
    transition: background-color 0.5s ease-in, border-bottom 0.5s ease-in;
    user-select: none;
    position: relative;
}

.leftPart {
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-left: 40px;
    align-items: center;
    position: relative;
}

.item {
    color: #8D96B2;
    cursor: pointer;
    font-size: 16px;
}

.activeItem {
    transition: color 0.3s ease-in;
    color: var(--tabText-color);
}

.slider {
    content: '';
    position: absolute;
    bottom: -2px;
    height: 2px;
    background-color: var(--tabText-color);
    transition: ease-in-out 0.3s;
}

.plusIcon {
    margin-top: 3px;
}

.iconContainer {
    margin-right: 40px;
    display: flex;
    gap: 16px;
}

.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.iconButton:focus {
    outline: none;
}

.valueContainer {
    color: #8D96B2;
    border: 1px solid #8D96B2;
    padding: 5px;
    border-radius: 5px;
    user-select: text;
}

.closeIcon {
    position: absolute;
    top: 15%;
    right: -0.8px;
    transform: translateY(-50%);
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: #FFFFFF;
}

.filterComponent {
    display: flex;
    flex-direction: row;
    position: absolute;
    justify-content: space-between;
    width: 380px;
    height: 2em;
    background-color: var(--sliderBtn-bg-color);
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, visibility 0.3s ease;
    opacity: 0;
    visibility: hidden;
    padding: 0.4em;
    right: 7.1em;
    top: 0.1em;
    z-index: 99999;
}

.filterComponent.visible {
    opacity: 1;
    visibility: visible;
}

.checkboxLabel {
    padding-top: 0.1em;
    display: flex;
    align-items: center;
    color: var(--switcherBt-text-color);
}

.checkboxContainer {
    margin-left: 0.5em;
    gap: 0.8em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.searchIconsContainer {
    margin-left: 1.5em;
    padding-right: 2em;
    display: flex;
    align-items: center;
    gap: 10px;
}

.customMrIco {
    margin-left: 0.2em;
}

.searchIcon {
    cursor: pointer;
}

.calendarImg {
    padding: 0.1em;
    width: 24px;
    height: 24px;
    margin-left: 0.2em;
    margin-right: 0.2em;
}

@media (max-width: 1024px) {
    .leftPart {
        margin-left: 2em;
    }
}

@media (max-width: 682px) {
    .leftPart {
        flex-wrap: wrap;
    }
    .slider {
        display: none;
    }
    .plusIcon {
        display: none;
    }
}

@media (max-width: 648px) {
    .content {
        height: 100px;
    }
}
@media (max-width: 426px) {
    .item {
        font-size: 12px;
    }
}
