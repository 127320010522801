.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--container-bg-color);
    color: var(--text-color);
    height: 80px;
    border-bottom: 2px solid var(--border-color);
    transition: background-color 0.5s ease-in, color 0.3s ease-in, border-bottom 0.5s ease-in;
}

.headerLeft {
    display: flex;
    flex-direction: row;
}

.headerRight{
    display: flex;
    flex-direction: row;
}

.title {
    font-family: 'Golos Regular';
    margin-left: 40px;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
}

.menuItemContainer {
    display: flex;
    flex-direction: row;
    gap: 16px;
    padding: 6px;
}

.demoText {
    margin-right: 1em;
    min-width: 500px;
    color: var(--switcherBt-text-color);
    font-size: 14px;
}

.demoLink {
    color: var( --badge-bg-color);
    cursor: pointer;
    text-decoration: none;
}

.demoLink:hover {
    text-decoration: underline;
}


@media (max-width: 1024px) {
    .title {
        margin-left: 1.3em;
    }
    .header {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
    }
    .headerLeft, .headerRight {
        align-items: center;
        flex-direction: row;
    }
    .headerLeft {
        margin-top: 0.5em;
        justify-content: space-between;
        width: 97%;
    }
    .headerRight {
        margin-left: 2em;
    }
    .demoText {
        margin-top: 0.5em;
        min-width: 0;
    }
}

@media (max-width: 540px) {
    .demoText {
        font-size: 12px;
        margin-bottom: 0.5em;
    }
    .headerLeft {
        align-items: flex-start;
        flex-direction: column;
    }
}
